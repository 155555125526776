<template>
   <div>
    <div   id="section-to-print" class=" m-auto">
        <div class="row m-0">
            <div class="col-xl-9 m-auto text-center">
                <img id="logo" src="../assets/logo.jpeg" width="250px"/>

            </div>

        </div>
        <div class="row mb-4">
            <div class="col-xl-6 col-lg-6 col-sm-6"> N : {{item.num_facture}} </div>
            <div class="col-xl-6 col-lg-6 col-sm-6"> {{item.date_transaction}}</div>
        </div>
        <table>
            <thead>
            <tr>
                <th class="Details">Article</th>
                <th class="pu">PU</th>
                <th class="pt">PT</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in facture_details" :key='item.id'>
                <td class="Details">{{item.qte}} x {{item.article.name}}<br>
                    <div   v-for="att in item.attributes" :key='att.id_attribute'>
                        <span>-<b>{{att.attribute_name}} : </b>{{att.name}}</span><br>
                    </div>
                </td>
                <td class="pu">{{item.unit_price}}</td>
                <td class="price">{{item.pt}}</td>
            </tr>

            <tr>
                <th rowspan="3" colspan="1"></th>
                <td>Total TTC</td>
                <td>{{total_ttc}}</td>
            </tr>
            <tr>
                <td>TVA 20%</td>
                <td>{{tva}}</td>
            </tr>
            <tr>
                <td>Total HT</td>
                <td>{{total_ht}}</td>
            </tr>
            </tbody>
        </table>
        <div class="text-center ">
            <p >Merci de votre visite</p>
            <p >RC 38899 Patente 64095027</p>
            <p >Tel 0624 33 58 83</p>
        </div>

    </div>
    <button class="btn-outline-danger mr-2" onclick="window.close();">Fermer</button>
    <button class="btn-outline-success"  onclick="window.print();">Imprimer</button>
</div>

</template>

<script>
    export default {
        name: "recu",
        created(){
            this.getFactureDetails()
            this.getFacture()

        },

        methods:{
            getFacture() {
                this.$http
                    .get('factures/' + this.$route.params.id)
                    .then(response => {
                        this.item = response.data
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
            getFactureDetails() {
                this.$http
                    .get('factures_detail/' + this.$route.params.id)
                    .then(response => {
                        this.facture_details = response.data
                        this.facture_details.forEach(function (item) {
                            item.pt = parseFloat(item.qte) * parseFloat(item.unit_price)
                        });
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },

        },
        computed:{
            total_ttc: function () {
                let sum_op = (max, cur) => max + cur;
                let sum = this.facture_details.map(el => parseFloat(el.pt)).reduce(sum_op, 0);
                let ttc = sum * (1 - (this.item.reduction) / 100);
                if (Number.isInteger(ttc)) return ttc
                else return ttc.toFixed(2)
            },
            tva: function () {
                return (parseFloat(this.total_ttc) / 6).toFixed(2)
            },
            total_ht: function () {
                return (parseFloat(this.total_ttc) - parseFloat(this.tva)).toFixed(2)
            },
        },
        data(){
            return{
                modes:['Carte Bancaire', 'Virement','Espece','Cheque'],
                facture_details:[],
                item:{},
                options : { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' },
                value: window.location.href,

            }
        }
    }
</script>

<style src="@/assets/imprimer.css"></style>
<style scoped>
    table ,td,tr,th{
        border-top: 1px solid black !important;
        border-collapse: collapse;
    }
    table{
        width: 97%;
    }


    @media print {
        #logo{
            width: 80%!important;
        }
        body {
            margin: 0 !important;
        }
        @page {
            margin: 0mm 5mm !important;
        }
    }

</style>
